import React from "react"
import { Link, graphql, img } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <section className="sec-top pt-5">
        <div className="pt-5" />
        <div className="container pt-5">
          <div className="blog-top">
            <div
              className="col-md-6"
              style={{
                display: "flex",
                zIndex: 10,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h1 className="mt-0">C24 Blogs</h1>
              <h3 className="text-wrap">
                <br/>
                All the blogs are from super talented Blogstars from the
                campuses we operate at
              </h3>
              <br />
              <br />
              <br />
              <form
                className="form-footer"
                style={{
                  height: "40px",
                  position: "relative",
                  top: "-40px",
                  display: "none",
                  justifyContent: "start",
                }}
              >
                <input
                  type="text"
                  className="col-md-8"
                  placeholder="Search Blog"
                  style={{
                    borderRadius: "4px 0 0 4px",
                    border: "0px solid grey",
                    borderRight: "none",
                  }}
                />
                <input
                  type="submit"
                  defaultValue="Search"
                  style={{ borderRadius: "0 4px 4px 0", borderWidth: "1px" }}
                />
              </form>
            </div>
            <div className="col-md-6 blog-top-img" align="center" style={{zIndex:10}}>
              <img src="/static/images/blog_icon.svg" style={{ width: "300px" }} alt=""/>
            </div>
          </div>
        </div>
        <div className="pt-5 pb-5" />
      </section>
      <section>
        <div className="wave wave1" />
        <div className="wave wave2" />
        <div className="wave wave3" />
      </section>
      <h3 className="text-muted container mt-5 pt-5">Featured</h3>
      <div className="px-5 container">
        <div className="row" style={{ paddingLeft: 0 }}>
          <div className="blog"   style={{borderBottom:"1px dotted lightgrey"}}>
            <div className="row">
              {posts.filter(({node})=>node.frontmatter.draft!==true).map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                const thumbnail = (node.frontmatter.thumbnail != null)?node.frontmatter.thumbnail.publicURL:""
                return (
                  <Link key={node.fields.slug} to={node.fields.slug} className="row" style={{color:"inherit",textDecoration:"none",paddingTop:"1em",paddingBottom:"1em",borderTop:"1px solid lightgrey"}}>
                    <div className="col-md-8">
                      <small className="text-muted">{node.timeToRead} min read</small>
                      <h2 className="text-break" style={{margin:0}}>{title}</h2>
                      <h6 style={{margin:".4em 0"}}>Written by {node.frontmatter.author + " , " +node.frontmatter.date }</h6>                          
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                        style={{marginTop:"1em",marginBottom:"1em",color:"grey"}}
                      />  
                      </div>
                    <hr/>
                    <div className="col-md-4" align="center">
                      <div
                        className="container"
                        style={{height: "max-content" }}
                        align="center"
                      >
                        <img
                          src={thumbnail}
                          className="blog-img-holder"
                          alt = ""
                        />
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>      
      <br/><br/>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            draft
            date(fromNow: true)
            title
            description
            thumbnail{
              publicURL
            }
            author
          }
        }
      }
    }
  }
`
